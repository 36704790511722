@import "./sass/fonts.scss";
@import "./sass/variables/colors";
@import "./sass/mixins/media";
@import "./sass/variables/colors.scss";
@import "./sass/variables/font-size.scss";
@import "./sass/cms.scss";
@import "./sass/icons.scss";

// @import "/src/sass/grid";
// @import "/src/sass/form";

* {
  -webkit-text-stroke-width: 0px;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: Tondo, Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  letter-spacing: 0;
  font-size: $body + px;
  line-height: 1.6;
  color: $primary;
  font-weight: 100;
}

.container {
  max-width: 1190px !important;
  margin: auto !important;
  width: 100%;
  padding: 0 15px !important;
}

.landing-page-layout {
  overflow-x: hidden;
}

a,
button {
  cursor: pointer;
}

b,
strong {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: $airtel-brand;
}
a:focus,
a:hover {
  color: initial;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  margin: 0;
}
p {
  margin-bottom: 5px;
}

h1,
h2,
h3 {
  font-weight: 500;
  margin: 0 0 20px;
}
h2 {
  font-size: $h2 + px;
}
h3 {
  font-size: $h3 + px;
}

h4 {
  font-size: $h4 + px;
}
*:focus {
  outline: none;
  box-shadow: none;
}

.error {
  color: $airtel-brand !important;
  margin: 4px 0 0;
}

.xs-visible {
  display: none;
  @include mobile {
    display: block;
  }
}

.xs-hidden {
  @include mobile {
    display: none !important;
  }
}

@include mobile {
  body {
    font-size: 14px;
  }
  .container {
    padding: 0 20px;
  }
  h2 {
    font-size: 28px;
  }
}

.accordion-toggle-faq-n:hover {
  color: red;
}
.somrthing-wrong button {
  margin-top: 18px;
  background: #f00;
  border: none;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
}
.somrthing-wrong {
  display: flex;
  flex-direction: column;
}
.adv-modal {
  display: block;
  margin: 0 !important;

  .modal-header {
    padding: 23px 14px;
    position: relative;
  }
 .modal-header .close {
    margin-top: -10px;
    font-size: 29px;
    font-weight: normal;
    color: #000;
    opacity: 1;
  } 

  .img-fluid {
    width: 100%;
  }
}
