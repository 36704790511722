/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
 $primary:#292c31;
 $white: #ffffff;
 $gray: #e8e8eb;
 $gray-1: #87888a;
 $gray-2: #f7f7fa;
 $gray-3: #696b6f;
 $black: #030303;
 $disable: #d4d4d4;
 $airtel-brand: #ED1C24;
 $orange: #fd7e14;
 $yellow: #EBA012;
 $dark-red: #9B1116;
 $dark-red2:  #6E0509;
 $brand-200: #e40000;
 $red: #DB3832;
 $fclr: #1C293B;
 $link:#4191DF;
 $green: #5ECB4E;
 $overlay-wt:rgba(0, 0, 0, 0.8);


