@use '../../../../sass/color.module.scss';
@use '../../../../sass/media.module.scss';
@use '../../../../sass/common.scss';

.landing-loading-container {
    overflow-y: hidden;
    height: 100vh;

    .home-header-container-skeleton {
        @include common.get-flex(space-between, center);
        background-color: #000000;
        height: 100px;
        padding: 0 32px;

        &>div {
            @include common.get-flex(null, center);
            gap: 16px;
        }
    }

    .announcement-bar-skeleton {
        min-height: 36px;
        background-color: #000000;
    }

    .home-recharges-skeleton {
        @include common.get-flex(center, center, column);
        padding: 70px;
        gap: 32px;
    }

    .wt-header-m-me-c {
        z-index: 1;
    }
}