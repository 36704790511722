@use 'sass:meta';
@use 'sass:string';

// convert map values to css properties and return
// "$skip-prop-list" should be used exclude property from maps
// Eg: for single value 'src'
// Eg: for multiple 'src font-family ...'
@mixin map-to-props($map, $skip-prop-list: '') {
  @if meta.type-of($map)==map {

    @each $prop,
    $value in $map {
      @if meta.type-of($value) !=map {
        @if (string.index($skip-prop-list, $prop)==null) {
          #{$prop}: #{$value};
        }
      }

      @else {
        @include map-to-props($value);
      }
    }
  }

  @else {
    @error "Invalid type of map: #{meta.type-of($map)} provided by expected is map!";
  }
}

// add flex properties with default values
@mixin get-flex($justifyContent: null,
  $alignItems: null,
  $direction: null,
  $important: null) {
  @if $important {
    display: flex !important;
  }

  @else {
    display: flex;
  }

  @if ($justifyContent) {
    justify-content: $justifyContent;
  }

  @if ($alignItems) {
    align-items: $alignItems;
  }

  @if ($direction) {
    flex-direction: $direction;
  }
}

@mixin fixed-fullpage-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}