@use '../../../../sass/color.module.scss';
@use '../../../../sass/media.module.scss';
@use '../../../../sass/common.scss';

// add flex properties with default values
@mixin get-flex($justifyContent: null,
    $alignItems: null,
    $direction: null,
    $important: null) {
    @if $important {
        display: flex !important;
    }

    @else {
        display: flex;
    }

    @if ($justifyContent) {
        justify-content: $justifyContent;
    }

    @if ($alignItems) {
        align-items: $alignItems;
    }

    @if ($direction) {
        flex-direction: $direction;
    }
}

.home-banner-skeleton {
    @include common.get-flex();

    @include media.screen-below(md) {
        @include common.get-flex(null, null, column);
    }

    .home-banner-content-skeleton {
        @include common.get-flex(null, null, column);
        flex: 1;
        padding: 30px 50px;
        gap: 16px;

        @include media.screen-below(md) {
            order: 2;
            @include common.get-flex(center, center, column);
        }

        @include media.screen-above(md) {
            padding: 80px;
        }
    }

    .home-banner-buttons-skeleton {
        @include common.get-flex(null, center);
        gap: 16px;
        margin-top: 32px;

        @include media.screen-below(md) {
            @include common.get-flex(center, center, column);
        }
    }

    .home-banner-actions-skeleton {
        @include common.get-flex(null, center);
        gap: 16px;
        margin-top: auto;
    }

    .home-banner-image-skeleton {
        flex: 1;
        padding: 16px;
        min-height: 204px;
    }
}