@use 'sass:map';

$breakpoints: (
  sm: 576px,
  md: 767px,
  lg: 1199px,
  xl: 1400px,
);

// if we want to check above given viewport size
@mixin screen-above($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map.get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  }

  @else {
    @error 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// if we want to check below given viewport size
@mixin screen-below($breakpoint) {
  @if map.has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map.get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }

  @else {
    @error 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// if we want to check between given viewport sizes
@mixin screen-between($lower, $upper) {
  @if map.has-key($breakpoints, $lower) and map.has-key($breakpoints, $upper) {
    $lower-breakpoint: map.get($breakpoints, $lower);
    $upper-breakpoint: map.get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  }

  @else {
    @if (map.has-key($breakpoints, $lower)==false) {
      @error 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map.has-key($breakpoints, $upper)==false) {
      @error 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

:export {
  sm: map.get($breakpoints, sm);
  md: map.get($breakpoints, md);
  lg: map.get($breakpoints, lg);
  xl: map.get($breakpoints, xl);
}