@font-face {
  font-family: Tondo;
  src: url('../assets/fonts/tondo-bold-webfont.eot');
  src: url('../assets/fonts/tondo-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/tondo-bold-webfont.woff2') format('woff2'), url('../assets/fonts/tondo-bold-webfont.woff') format('woff'), url('../assets/fonts/tondo-bold-webfont.ttf') format('truetype'), url('../assets/fonts/tondo-bold-webfont.svg#tondobold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Tondo;
  src: url('../assets/fonts/tondo-regular-webfont.eot');
  src: url('../assets/fonts/tondo-regular-webfont.eot?#iefix') format('embedded-opentype'),
   url('../assets/fonts/tondo-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/tondo-regular-webfont.woff') format('woff'), 
    url('../assets/fonts/tondo-regular-webfont.ttf') format('truetype'),
     url('../assets/fonts/tondo-regular-webfont.svg#tondoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Tondo;
  src: url('../assets/fonts/tondo-light-webfont.eot');
  src: url('../assets/fonts/tondo-light-webfont.eot?#iefix') format('embedded-opentype'),
   url('../assets/fonts/tondo-light-webfont.woff2') format('woff2'),
    url('../assets/fonts/tondo-light-webfont.woff') format('woff'),
     url('../assets/fonts/tondo-light-webfont.ttf') format('truetype'),
      url('../assets/fonts/tondo-light-webfont.svg#tondolight') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "airtel-icons-ng";
  src: url('../assets/fonts/airtel-icons-ng.eot');
  src: url('../assets/fonts/airtel-icons-ng.eot?#iefix') format('eot'),
      url('../assets/fonts/airtel-icons-ng.woff') format('woff'),
      url('../assets/fonts/airtel-icons-ng.ttf') format('truetype'),
      url('../assets/fonts/airtel-icons-ng.svg#airtel-icons') format('svg');

     
  font-weight: 400;
  font-style: normal
}
