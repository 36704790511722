
@mixin iped {
    @media (min-width:767px)  and (max-width:1024px){
      @content;
    }
  }
  @mixin mobile {
    @media (max-width:767px) {
      @content;
    }
  }
  