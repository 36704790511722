


@font-face {
	font-family: "airtel-icons";
	src: url('../assets/fonts/icons/airtel-icons.eot');
	src: url('../assets/fonts/icons/airtel-icons.eot?#iefix') format('eot'),
		// url('../assets/fonts/icons/airtel-icons.woff2') format('woff2'),
		url('../assets/fonts/icons/airtel-icons.woff') format('woff'),
		url('../assets/fonts/icons/airtel-icons.ttf') format('truetype'),
		// url('../assets/fonts/icons/airtel-icons.svg#airtel-icons') format('svg');
}
@font-face {
    font-family: 'Glyphter';
    src: url('../assets/fonts/Glyphter.eot'),
      url('../assets/fonts/Glyphter.woff') format('woff'),
      url('../assets/fonts/Glyphter.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

[class*="icon-"], [class^=icon-]:before {
	font-family: "airtel-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


[class*=gt-gethelp-],
[class^=gt-gethelp-]:before {
    display: inline-block;
    font-family: Glyphter;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.gt-gethelp-webportalicons-06:before {
    content: "\0041"
}

.gt-gethelp-webportalicons-26:before {
    content: "\0042"
}

.gt-gethelp-webportalicons-35:before {
    content: "\0043"
}


[class*="ng-icon-"], 
[class^=ng-icon-] {
  font-family: airtel-icons-ng !important;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-filled-blob-1:before { content: "\F101";}
.icon-filled-blob-2:before { content: "\F102";}
.icon-filled-blob-3:before { content: "\F103";}
.icon-filled-cashback:before { content: "\F104";}
.icon-filled-checkmark:before { content: "\F105";}
.icon-filled-circle:before { content: "\F106";}
.icon-filled-facebook:before { content: "\F107";}
.icon-filled-google:before { content: "\F108";}
.icon-filled-information:before { content: "\F109";}
.icon-filled-linkedin:before { content: "\F10A";}
.icon-filled-location:before { content: "\F10B";}
.icon-filled-rupee:before { content: "\F10C";}
// .icon-filled-twitter:before { content: "\F10D";}
.icon-filled-twitter:before { 
  content: "";
    background: url(https://cdn-webportal.airtelstream.net/website/zambia/assets/images/twitter-x-icon.svg) no-repeat 50%;
    position: absolute;
    top: 6px;
    left: -11px;
    width: 22px;
    height: 22px;
    opacity: .6
}
.icon-filled-warning:before { content: "\F10E";}
.icon-filled-youtube:before { content: "\F10F";}
.icon-outlined-404:before { content: "\F110";}
.icon-outlined-aim:before { content: "\F111";}
.icon-outlined-airplane:before { content: "\F112";}
.icon-outlined-airtel-money:before { content: "\F113";}
.icon-outlined-allplans:before { content: "\F114";}
.icon-outlined-arrow-down:before { content: "\F115";}
.icon-outlined-arrow-left:before { content: "\F116";}
.icon-outlined-arrow-right:before { content: "\F117";}
.icon-outlined-link-arrow-right:before,
.icon-outlined-link-right-arrow:before {content: "\F16E"
}

.icon-outlined-arrow-up:before { content: "\F118";}
.icon-outlined-back-to-top:before { content: "\F119";}
.icon-outlined-billing:before { content: "\F11A";}
.icon-outlined-broadband:before { content: "\F11B";}
.icon-outlined-car:before { content: "\F11C";}
.icon-outlined-cash:before { content: "\F11D";}
.icon-outlined-chat:before { content: "\F11E";}
.icon-outlined-checkmark:before { content: "\F11F";}
.icon-outlined-chevron-down:before { content: "\F120";}
.icon-outlined-chevron-up:before { content: "\F121";}
.icon-outlined-circle-checkmark:before { content: "\F122";}
.icon-outlined-circle-information:before { content: "\F123";}
.icon-outlined-clock:before { content: "\F124";}
.icon-outlined-coverage:before { content: "\F125";}
.icon-outlined-credit-card:before { content: "\F126";}
.icon-outlined-cross:before { content: "\F127";}
.icon-outlined-debit-card:before { content: "\F128";}
.icon-outlined-desktop-lock:before { content: "\F129";}
.icon-outlined-directions:before { content: "\F12A";}
.icon-outlined-doublearrow-left:before { content: "\F12B";}
.icon-outlined-doublearrow-right:before { content: "\F12C";}
.icon-outlined-equal-sign:before { content: "\F12D";}
.icon-outlined-gamepad:before { content: "\F12E";}
.icon-outlined-gift:before { content: "\F12F";}
.icon-outlined-group:before { content: "\F130";}
.icon-outlined-india:before { content: "\F131";}
.icon-outlined-international:before { content: "\F132";}
.icon-outlined-list:before { content: "\F133";}
.icon-outlined-local:before { content: "\F134";}
.icon-outlined-location:before { content: "\F135";}
.icon-outlined-magnifier:before { content: "\F136";}
.icon-outlined-magnifierplus:before { content: "\F137";}
.icon-outlined-mail-atachment:before { content: "\F138";}
.icon-outlined-mail:before { content: "\F139";}
.icon-outlined-menu:before { content: "\F13A";}
.icon-outlined-minus:before { content: "\F13B";}
.icon-outlined-modem:before { content: "\F13C";}
.icon-outlined-movie:before { content: "\F13D";}
.icon-outlined-music:before { content: "\F13E";}
.icon-outlined-paperplane:before { content: "\F13F";}
.icon-outlined-pen:before { content: "\F140";}
.icon-outlined-phone:before { content: "\F141";}
.icon-outlined-picture:before { content: "\F142";}
.icon-outlined-pinch:before { content: "\F143";}
.icon-outlined-plans:before { content: "\F144";}
.icon-outlined-plus:before { content: "\F145";}
.icon-outlined-questionmark:before { content: "\F146";}
.icon-outlined-recharge:before { content: "\F147";}
.icon-outlined-refresh:before { content: "\F148";}
.icon-outlined-road-sign:before { content: "\F149";}
.icon-outlined-saved-card:before { content: "\F14A";}
.icon-outlined-secure:before { content: "\F14B";}
.icon-outlined-simcard:before { content: "\F14C";}
.icon-outlined-smartphone:before { content: "\F14D";}
.icon-outlined-startmyplan:before { content: "\F14E";}
.icon-outlined-store:before { content: "\F14F";}
.icon-outlined-stream:before { content: "\F150";}
.icon-outlined-summary:before { content: "\F151";}
.icon-outlined-tag:before { content: "\F152";}
.icon-outlined-tap:before { content: "\F153";}
.icon-outlined-tile:before { content: "\F154";}
.icon-outlined-truck:before { content: "\F155";}
.icon-outlined-user:before { content: "\F156";}
.icon-outlined-video:before { content: "\F157";}
.icon-outlined-warning:before { content: "\F158";}
.icon-outlined-website:before { content: "\F159";}
.icon-outlined-phone-local:before { content: "\F15A";}
.icon-outlined-tvcable:before { content: "\F15B";}
.icon-filled-tower:before { content: "\F15C";}
.icon-filled-lock:before { content: "\F15D";}
.icon-filled-star:before { content: "\F15E";}
.icon-outlined-circle-plus:before { content: "\F15F";}
.icon-outlined-delete:before { content: "\F160";}
.icon-outlined-previous-page:before { content: "\F161";}
.icon-outlined-next-page:before { content: "\F162";}
.icon-outlined-retry:before { content: "\F163";}
.icon-outlined-mobile-hand:before { content: "\F164";}
.icon-outlined-document:before { content: "\F165";}
.icon-outlined-identification:before { content: "\F166";}
.icon-outlined-other-document:before { content: "\F167";}
.icon-outlined-photo:before { content: "\F168";}
.icon-outlined-mobile-cover:before { content: "\F169";}
.icon-outlined-settings:before { content: "\F16A";}
.icon-outlined-speed:before { content: "\F16B";}
.icon-outlined-wallet:before { content: "\F16C";}
.icon-outlined-sim-open:before { content: "\F16D";}
.icon-outlined-link-arrow-right:before { content: "\F16E";}
.icon-outlined-truck-status:before { content: "\F16F";}
.icon-outlined-tunes:before { content: "\F170";}
.icon-outlined-rewards:before { content: "\F171";}
.icon-outlined-postpaidbenefits:before { content: "\F172";}
.icon-outlined-moneyapp:before { content: "\F173";}
.icon-outlined-manageairtel:before { content: "\F174";}
.icon-outlined-india-call:before { content: "\F175";}
.icon-outlined-hdc-onnection:before { content: "\F176";}
.icon-outlined-activate-sim:before { content: "\F177";}
.icon-outlined-last-page:before { content: "\F178";}
.icon-outlined-first-page:before { content: "\F179";}
.icon-outlined-dongle:before { content: "\F17A";}
.icon-outlined-add-new:before { content: "\F17B";}
.icon-outlined-airtel-money-wallet:before { content: "\F17C";}
.icon-outlined-mobile-apps:before { content: "\F17D";}
.icon-outlined-bill:before { content: "\F17E";}
.icon-outlined-calendar:before { content: "\F17F";}
.icon-outlined-camera:before { content: "\F180";}
.icon-outlined-cart:before { content: "\F181";}
.icon-outlined-click-cursor:before { content: "\F182";}
.icon-outlined-customer-support:before { content: "\F183";}
.icon-outlined-download:before { content: "\F184";}
.icon-outlined-dth:before { content: "\F185";}
.icon-outlined-edit:before { content: "\F186";}
.icon-outlined-external-page:before { content: "\F187";}
.icon-outlined-family:before { content: "\F188";}
.icon-outlined-feedback:before { content: "\F189";}
.icon-outlined-helpful-up:before { content: "\F18A";}
.icon-outlined-helpful-down:before { content: "\F18B";}
.icon-outlined-circle-helpful-up:before { content: "\F18C";}
.icon-outlined-circle-helpful-down:before { content: "\F18D";}
.icon-outlined-landline:before { content: "\F18E";}
.icon-outlined-livechat:before { content: "\F18F";}
.icon-outlined-logout:before { content: "\F190";}
.icon-outlined-menu-nav:before { content: "\F191";}
.icon-outlined-monthly-bill:before { content: "\F192";}
.icon-outlined-movies:before { content: "\F193";}
.icon-outlined-notification:before { content: "\F194";}
.icon-outlined-offers:before { content: "\F195";}
.icon-outlined-payment:before { content: "\F196";}
.icon-outlined-postpaid:before { content: "\F197";}
.icon-outlined-prepaid:before { content: "\F198";}
.icon-outlined-print:before { content: "\F199";}
.icon-outlined-privacy:before { content: "\F19A";}
.icon-outlined-salary:before { content: "\F19B";}
.icon-outlined-star:before { content: "\F19C";}
.icon-outlined-share:before { content: "\F19D";}
.icon-outlined-wifi:before { content: "\F19E";}
.icon-outlined-payment-bank:before { content: "\F19F";}
.icon-outlined-interest:before { content: "\F1A0";}
.icon-outlined-bank-insurance:before { content: "\F1A1";}
.icon-outlined-payment-bank2:before { content: "\F1A2";}
.icon-filled-map-current-location:before { content: "\F1A3";}
.icon-outlined-network-issues:before { content: "\F1A4";}
.icon-outlined-help:before { content: "\F1A5";}
.icon-outlined-search-files:before { content: "\F1A6";}
.icon-outlined-send-sim-card:before { content: "\F1A7";}
.icon-outlined-write:before { content: "\F1A8";}
.icon-smartphone-hand:before { content: "\F1A9";}

.icon-outlined-wifi2:before {
    content: "\F1A6";
}


/*.ng-icon-outlined-Hello-Tunes:before{content:'\0041';}
.ng-icon-outlined-Instagram-Logo-Black:before{content:'\0042';}
.ng-icon-outlined-KYC:before{content:'\0043';}
.ng-icon-outlined-Location:before{content:'\0044';}
.ng-icon-outlined-NUVU:before{content:'\0045';}
.ng-icon-outlined-PostPAID:before{content:'\0046';}
.ng-icon-outlined-Premier:before{content:'\0047';}
.ng-icon-outlined-Devices:before{content:'\0048';}
.ng-icon-outlined-Recharge-Bundles:before{content:'\0049';}
.ng-icon-outlined-smartCONNECT:before{content:'\004a';}
.ng-icon-outlined-444:before{content:'\004b';}
.ng-icon-outlined-Daily-Plans:before{content:'\004c';}
.ng-icon-outlined-Data-Bundles:before{content:'\004d';}*/
.ng-icon-outlined-NUVU:before{content:'\0041';}
.ng-icon-outlined-PostPAID:before{content:'\0042';}
.ng-icon-outlined-Premier:before{content:'\0043';}
.ng-icon-outlined-Recharge-Bundles:before{content:'\0044';}
.ng-icon-outlined-smartCONNECT:before{content:'\0045';}
.ng-icon-outlined-444:before{content:'\0046';}
.ng-icon-outlined-Daily-Plans:before{content:'\0047';}
.ng-icon-outlined-Location:before{content:'\0048';}
.ng-icon-outlined-Data-Bundles:before{content:'\0049';}
.ng-icon-outlined-Devices:before{content:'\004a';}
.ng-icon-outlined-Hello-Tunes:before{content:'\004b';}
.ng-icon-outlined-KYC:before{content:'\004c';}
.ng-icon-outlined-Instagram-Logo-Black:before{content:'\004d';}
.icon-NUVU:before{content:'\0041';}
.icon-PostPAID:before{content:'\0042';}
.icon-Premier:before{content:'\0043';}
.icon-Recharge Bundles:before{content:'\0044';}
.icon-smartCONNECT:before{content:'\0045';}
.icon-444:before{content:'\0046';}
.icon-Daily Plans:before{content:'\0047';}
.icon-Location:before{content:'\0048';}
.icon-Data Bundles:before{content:'\0049';}
.icon-Devices:before{content:'\004a';}
.icon-Hello Tunes:before{content:'\004b';}
.icon-KYC:before{content:'\004c';}
.icon-Instagram Logo Black:before{content:'\004d';}